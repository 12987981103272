import * as React from 'react';
import { useLogger } from '@shared-ui/logger-context';

export const Header: React.FC = () => {
  const { info } = useLogger();

  info('Rendering Header');

  return (
    <header className="sp_header">
      <div className="sh_header_in">
        <a href="/" className="sh_header_logo">
          <img src="/images/logo.png" alt="logo" />
        </a>
      </div>
    </header>
  );
};
