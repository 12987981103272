import * as React from 'react';
import { useLogger } from '@shared-ui/logger-context';

export const Footer: React.FC = () => {
  const { info } = useLogger();

  info('Rendering Footer');

  return (
    <footer className="sp_footer">
      <div className="container">
        <ul className="sp_footernav">
          <li>
            <a href="https://www.vrbo.com/trust/">Trust and Safety</a>
          </li>
          <li>
            <a href="https://www.vrbo.com/media-center">Media Center</a>
          </li>
          <li>
            <a href="https://www.vrbo.com/l/affiliates/">Affiliates</a>
          </li>
          <li>
            <a href="https://www.vrbo.com/">Find A Rental</a>
          </li>
          <li>
            <a href="https://www.vrbo.com/about/">About Us</a>
          </li>
          <li>
            <a href="https://www.vrbo.com/en-us/list">Homeowners</a>
          </li>
          <li>
            <a href="https://ir.expediagroup.com/investors-overview/default.aspx">Investors</a>
          </li>
          <li>
            <a href="https://lifeatexpediagroup.com/brands?utm_source=vrbo&%3Butm_medium=homepage%23brands-vrbo">
              Careers
            </a>
          </li>
          <li>
            <a href="https://www.vrbo.com/list">Getting Started</a>
          </li>
          <li>
            <a href="https://www.vrbo.com/vacation-ideas">Travel Ideas</a>
          </li>
          <li>
            <a href="https://www.trustpilot.com/review/www.homeaway.com">Vrbo Reviews</a>
          </li>
        </ul>
        <p>
          Use of this website constitutes acceptance of the Vrbo&nbsp;
          <a href="https://www.vrbo.com/legal/terms-and-conditions">Terms and Conditions</a> ,&nbsp;
          <a href="https://www.vrbo.com/legal/privacy-policy">Privacy Policy</a> and&nbsp;
          <a href="https://www.vrbo.com/dnsmpi/">Do Not Sell My Personal Information</a>.
        </p>
        <p className="footecopy">
          &copy; Copyright 2021 Vrbo, an&nbsp;{' '}
          <img src="/images/eg_logo.svg" style={{ verticalAlign: 'middle', width: '105px' }} /> Company. All rights
          reserved.
        </p>
      </div>
    </footer>
  );
};
